<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Reportes</h4>
            <div class="small text-muted">Reportes de ventas</div>
          </b-col>                        
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="12">
          <b-card>
            <b-row >
              <b-col sm="10">            
                <b-row>            
                  <b-col sm="12" class="pb-1">
                    <b-row>         
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                      </b-col>
                      <b-col md="3" class="p-1 pb-1">
                        <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                      </b-col> 
                      <b-col sm="3" class="p-1 pb-1">
                        <v-select :options="arr.filters.status_imputation" v-model="filters.status_imputation" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col>                    
                    </b-row>
                  </b-col>         
                  <b-col sm="12">
                    <b-row>
                      <b-col sm="3" v-if="moduleSellersActive" class="p-1">
                        <v-select :options="arr.filters.sellers" v-model="filters.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>                                    
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                      </b-col>  
                      <b-col sm="3" class="p-1">
                        <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                      </b-col> 
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="2" class="p-1">
                <b-row>
                  <b-col sm="12">
                    <b-button variant="outline-dark" @click="filterTab()" size="sm" class="pull-right">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>                       
                </b-row>
              </b-col>
            </b-row>   
          </b-card>
        </b-col>
      </b-row>
      
      <b-tabs class="mb-3">          
        <b-tab title="IVA Ventas" @click="loadByFilter()">
          
          <b-col sm="12" class="mr-0 pr-0" v-if="table.items.length && !table.preload">
            <b-link>
              <export-excel
                class = 'pull-right mt-2 mb-2'
                :data = "arr.export"
                worksheet = "IVA Ventas"
                name = "taxes-sales.xls">
                Exportar Datos
              </export-excel>
            </b-link>
          </b-col>

          <b-table-simple hover small caption-top responsive v-if="table.items.length && !table.preload">
            <b-thead head-variant="dark">                    
              <b-tr>
                <b-th width="15%" class="text-center">Comprobante</b-th>
                <b-th width="7%" class="text-center">Fecha</b-th>
                <b-th width="7%" class="text-center">Vto</b-th>
                <b-th class="text-left">Cliente</b-th>
                <b-th width="7%" class="text-right">Neto</b-th>
                <b-th width="7%" class="text-right" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="indexI">{{itemI.label}}</b-th>
                <b-th width="7%" class="text-right">Total</b-th>
              </b-tr>
            </b-thead> 
            <b-tbody>
              <b-tr v-for="item in table.items" :key="item.id">
                <b-td class="text-center align-middle">
                  <div v-if="item.type_voucher && item.point_sale" 
                        :class="{'text-danger font-weight-bold':(item.afip_error && !item.point_sale.nd), 'text-success font-weight-bold':item.afip && item.afip_qr && !item.point_sale.nd}" 
                        :title="item.afip_error">                           
                    {{item.type_voucher.name}} 
                    {{item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')}}
                  </div>
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date).format('DD/MM/YYYY')}}
                </b-td>

                <b-td class="text-center align-middle">
                  {{moment(item.date_expiration).format('DD/MM/YYYY')}}                    
                </b-td>
                
                <b-td>
                  <div v-if="item.customer">
                    <span style="font-size: 9px">{{item.customer.data_fiscal.documents_number}} / {{item.customer.data_fiscal.fiscal_condition.name}}</span>
                    <br>
                    <b>{{item.customer.name}}</b>
                  </div>                    
                </b-td>
                
                <b-td class="text-right align-middle">
                  <div v-if="item.currency">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_net)}})</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_net)}}</b>
                    </div>                        
                  </div>                    
                </b-td>
                
                <b-td class="text-right align-middle" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="indexI">
                  <div v-if="item.currency">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item['amount_iva_' + itemI.label])}})</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item['amount_iva_' + itemI.label])}}</b>
                    </div>
                  </div>
                </b-td>
                
                <b-td class="text-right align-middle">                    
                  <div v-if="item.currency">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}})</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                    </div>                        
                  </div>                                        
                </b-td>
              </b-tr>

              <b-tr>
                <b-td class="text-right align-middle badge-dark" colspan="4"></b-td>

                <b-td class="text-right align-middle badge-light" width="7%">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(totals.net)}}</b>
                </b-td>

                <b-td width="7%" class="text-right badge-light" v-for="(itemI, indexI) in arr.columns.iva_conditions" :key="indexI">
                  <b style="font-size:15px;" v-if="(itemI.total_iva > 0)">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemI.total_iva)}}</b>
                  <b style="font-size:15px;" v-else>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(itemI.total_net)}}</b>
                </b-td>

                <b-td class="text-right align-middle badge-light" width="7%">
                  <b style="font-size:15px;">{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(totals.total)}}</b>
                </b-td>
              </b-tr>              
            </b-tbody>  
          </b-table-simple>  
          
          <b-row align-h="end" v-if="table.items.length && !table.preload">
            <b-col sm="12">            
              <b-table-simple hover small responsive bordered>               
                <b-tbody> 

                </b-tbody>
              </b-table-simple>            
            </b-col>
          </b-row>

          <div class="text-center" v-if="table.preload">
            <b-spinner label="Spinning"></b-spinner>
          </div>
          
          <b-alert v-if="!table.items.length && !table.preload" variant="warning" show>No se encontraron registros</b-alert>
        </b-tab>        
        <b-tab title="Ventas" @click="loadByFilterReport()">
          <Pivot :dataJson="pivot.data" v-if="pivot.data && !pivot.preload"/> 

          <div class="text-center" v-if="pivot.preload">
            <b-spinner label="Spinning"></b-spinner>
          </div>          
        </b-tab> 
      </b-tabs>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
        </b-row>
      </b-card>
    
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Param from '@/config/parameters'
  import moment from 'moment'  
  import Pivot from '@/components/inc/pivot/index'

  export default {     
    components: {
      Pivot,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        },  
        table : {
          items: [],          
          filter: null,
          preload: true,
        }, 
        pivot: {
          data: null,
          preload: true,
        },
        arr: {
          filters : {
            customers: [],
            sellers: [],
            points_sales: [],
            status_imputation: [
              {code: true, label: 'Cancelado'},
              {code: false, label: 'Pendiente'}
            ]
          },   
          columns : {
            iva_conditions: [],
          }                    
        },
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          sellers: null,
          points_sales: null,
          status_imputation: null,
          paginate: false,
        },  
        totals: {
          net: 0,
          total: 0,
        },
        tabs: {
          iva: true,
          report: false,
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()
      this.loadByFilter()      
    },
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },
    },    
    methods: {
      filterLoad() {
        this.filterLoadCustomers()          
        this.filterLoadSellers()   
        this.filterLoadPointSale()   
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
        this.getIvaConditions()
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {         
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }                             
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.sellers = []
          data.forEach(element => {                            
            this.arr.filters.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },   
      getIvaConditions() {
        var result = serviceAPI.obtenerCondicionesIva()
        result.then((response) => {          
          var data = response.data    

          data.forEach(element => {            
            this.arr.columns.iva_conditions.push({ code: element.id, label: element.name, total_net: 0, total_iva: 0 })
          });
          
        })
      },
      filterTab() {
        if(this.tabs.iva){
          this.loadByFilter()    
        } else {
          this.loadByFilterReport()    
        }
      },
      loadByFilter() {        
        this.table.preload = true

        this.tabs.iva = true
        this.tabs.report = false

        this.totals.net = 0
        this.totals.total = 0

        this.arr.columns.iva_conditions.forEach(elementIva => {
          elementIva.total_net = 0,
          elementIva.total_iva = 0
        });

        var result = serviceAPI.filtrarComprobante(this.filters)

        result.then((response) => {          
          var data = response.data
          this.table.items = data

          data.forEach(element => {
            element['amount_net'] = 0

            this.arr.columns.iva_conditions.forEach(elementIva => {
              element['amount_iva_' + elementIva.label] = 0              

              element.details.forEach(elementDet => {
                if(elementDet.iva_conditions_id == elementIva.code){
                  if(elementDet.amount_iva > 0){
                    
                    element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] + parseFloat(elementDet.amount_iva)
                    element['amount_net'] = element['amount_net'] + parseFloat(elementDet.amount_net)
                                        
                    if(element.type_voucher.type_balance == '-'){  
                      elementIva.total_iva = elementIva.total_iva - parseFloat(elementDet.amount_iva)
                    } else {
                      elementIva.total_iva = elementIva.total_iva + parseFloat(elementDet.amount_iva)
                    }

                    if(element.type_voucher.type_balance == '-'){
                      this.totals.net = this.totals.net - parseFloat(elementDet.amount_net)
                    } else {
                      this.totals.net = this.totals.net + parseFloat(elementDet.amount_net)
                    }
                  } else {
                    element['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label] + parseFloat(elementDet.amount_net)

                    if(element.type_voucher.type_balance == '-'){
                      elementIva.total_net = elementIva.total_net - parseFloat(elementDet.amount_net)
                    } else {
                      elementIva.total_net = elementIva.total_net + parseFloat(elementDet.amount_net)
                    }
                  }
                }
              });
            });

            if(element.type_voucher.type_balance == '-'){
              this.totals.total = this.totals.total - parseFloat(element.amount_total)
            } else {
              this.totals.total = this.totals.total + parseFloat(element.amount_total)
            }            
          });

          this.table.preload = false
          this.prepareExport(data)
        })
        .catch(error => {          
          this.table.preload = false
          this.$awn.alert(Error.showError(error))
        });
      },   
      loadByFilterReport() {
        this.pivot.preload = true
        
        this.tabs.iva = false
        this.tabs.report = true

        var result = serviceAPI.filtrarComprobante(this.filters)

        result.then((response) => {          
          var data = response.data
        
          this.pivot.data = this.processPivot(data)

          this.pivot.preload = false
        })
        .catch(error => {          
          this.pivot.preload = false
          this.$awn.alert(Error.showError(error))
        });
      },
      prepareExport(data) {        
        this.arr.export = []

        data.forEach(element => {
          var customersName = ""
          var customersCUIT = ""
          var customersCondFiscal = ""          
          var voucherReference = ""

          if(element.customer) {            
            customersName = element.customer.name
            customersCUIT = element.customer.data_fiscal.documents_number
            customersCondFiscal = element.customer.data_fiscal.fiscal_condition.name
          }          
          if(element.type_voucher) {            
            voucherReference = element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0')
          }
          
          var arrExport = []

          arrExport["voucherReference"] = voucherReference,      
          arrExport["date"] = element.date,
          arrExport["date_expiration"] = element.date_expiration,    
          arrExport["customersName"] = customersName,            
          arrExport["customersCUIT"] = customersCUIT,
          arrExport["customersCondFiscal"] = customersCondFiscal,

          arrExport['amount_net'] = element.amount_net

          this.arr.columns.iva_conditions.forEach(elementIva => {
            arrExport['amount_iva_' + elementIva.label] = element['amount_iva_' + elementIva.label]
          });

          arrExport["amount_total"] = element.amount_total,

          this.arr.export.push(arrExport)
        });
      },

      processPivot(data) {
        var addData = []        
        data.forEach(element => {
          addData.push({
            date: element.date,
            staff: (element.staff) ? element.staff.name : '',
            seller: (element.seller) ? element.seller.name: 'Sin vendedor',
            type_voucher: (element.type_voucher) ? element.type_voucher.name : '',
            point_sale: (element.point_sale) ? element.point_sale.point_sale : '',
            customer: (element.customer) ? element.customer.name : '',
            number: element.number,            
            amount_total: (element.type_voucher.type_balance == '+') ? parseFloat(element.amount_total) : parseFloat(element.amount_total) * -1,
          })
        });

        return {
          dataSource: {                  
            data: addData,
          },
          slice: {
            rows: [
              {
                uniqueName: "seller",
                sort: "asc",
              },                 
              {
                uniqueName: "staff",
                sort: "asc",
              },              
              {
                uniqueName: "customer",
                sort: "asc",
              },                            
            ],
            columns: [
              {
                uniqueName: "date.Month",
                sort: "asc",
              },                  
            ],
            measures: [
              {
                uniqueName: "amount_total",
                aggregation: "sum"
              },                                   
            ],
            expands: {
              expandAll: false,
              rows: [],
              columns: []
            },
            drills: {
              drillAll: false
            }
          },
          options: {
            grid: {
              type: "compact",
              title: "",
              showFilter: true,
              showHeaders: true,
              showTotals: false,
              showGrandTotals: "off",
              showHierarchies: true,
              showHierarchyCaptions: true,
              showReportFiltersArea: true
            },
            configuratorActive: false,
            configuratorButton: true,
            showAggregations: true,
            showCalculatedValuesButton: true,
            drillThrough: true,
            showDrillThroughConfigurator: true,
            sorting: "on",
            datePattern: "dd/MM/yyyy",
            dateTimePattern: "dd/MM/yyyy HH:mm:ss",
            saveAllFormats: false,
            showDefaultSlice: true,
            defaultHierarchySortName: "asc"
          },
          formats: [
            {
              name: "",
              thousandsSeparator: " ",
              decimalSeparator: ".",
              decimalPlaces: 2,
              maxSymbols: 20,
              currencySymbol: "",
              currencySymbolAlign: "left",
              nullValue: " ",
              infinityValue: "Infinity",
              divideByZeroValue: "Infinity"
            }
          ],
          localization: "https://cdn.webdatarocks.com/loc/es.json"                     
        }    
      },        
    }    
  }
</script>